.loading {
    width: 192px;
    height: 192px;
}

.image-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 172px;
}

.image-container img {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
}

.image-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wording {
    margin-top: 16px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    color: #2A456F;
}